var exports = {};

exports = function generate_allOf(it, $keyword, $ruleType) {
  var out = " ";
  var $schema = it.schema[$keyword];
  var $schemaPath = it.schemaPath + it.util.getProperty($keyword);
  var $errSchemaPath = it.errSchemaPath + "/" + $keyword;
  var $breakOnError = !it.opts.allErrors;
  var $it = it.util.copy(it);
  var $closingBraces = "";
  $it.level++;
  var $nextValid = "valid" + $it.level;
  var $currentBaseId = $it.baseId,
      $allSchemasEmpty = true;
  var arr1 = $schema;

  if (arr1) {
    var $sch,
        $i = -1,
        l1 = arr1.length - 1;

    while ($i < l1) {
      $sch = arr1[$i += 1];

      if (it.opts.strictKeywords ? typeof $sch == "object" && Object.keys($sch).length > 0 || $sch === false : it.util.schemaHasRules($sch, it.RULES.all)) {
        $allSchemasEmpty = false;
        $it.schema = $sch;
        $it.schemaPath = $schemaPath + "[" + $i + "]";
        $it.errSchemaPath = $errSchemaPath + "/" + $i;
        out += "  " + it.validate($it) + " ";
        $it.baseId = $currentBaseId;

        if ($breakOnError) {
          out += " if (" + $nextValid + ") { ";
          $closingBraces += "}";
        }
      }
    }
  }

  if ($breakOnError) {
    if ($allSchemasEmpty) {
      out += " if (true) { ";
    } else {
      out += " " + $closingBraces.slice(0, -1) + " ";
    }
  }

  return out;
};

export default exports;