var exports = {};

exports = function generate_format(it, $keyword, $ruleType) {
  var out = " ";
  var $lvl = it.level;
  var $dataLvl = it.dataLevel;
  var $schema = it.schema[$keyword];
  var $schemaPath = it.schemaPath + it.util.getProperty($keyword);
  var $errSchemaPath = it.errSchemaPath + "/" + $keyword;
  var $breakOnError = !it.opts.allErrors;
  var $data = "data" + ($dataLvl || "");

  if (it.opts.format === false) {
    if ($breakOnError) {
      out += " if (true) { ";
    }

    return out;
  }

  var $isData = it.opts.$data && $schema && $schema.$data,
      $schemaValue;

  if ($isData) {
    out += " var schema" + $lvl + " = " + it.util.getData($schema.$data, $dataLvl, it.dataPathArr) + "; ";
    $schemaValue = "schema" + $lvl;
  } else {
    $schemaValue = $schema;
  }

  var $unknownFormats = it.opts.unknownFormats,
      $allowUnknown = Array.isArray($unknownFormats);

  if ($isData) {
    var $format = "format" + $lvl,
        $isObject = "isObject" + $lvl,
        $formatType = "formatType" + $lvl;
    out += " var " + $format + " = formats[" + $schemaValue + "]; var " + $isObject + " = typeof " + $format + " == 'object' && !(" + $format + " instanceof RegExp) && " + $format + ".validate; var " + $formatType + " = " + $isObject + " && " + $format + ".type || 'string'; if (" + $isObject + ") { ";

    if (it.async) {
      out += " var async" + $lvl + " = " + $format + ".async; ";
    }

    out += " " + $format + " = " + $format + ".validate; } if (  ";

    if ($isData) {
      out += " (" + $schemaValue + " !== undefined && typeof " + $schemaValue + " != 'string') || ";
    }

    out += " (";

    if ($unknownFormats != "ignore") {
      out += " (" + $schemaValue + " && !" + $format + " ";

      if ($allowUnknown) {
        out += " && self._opts.unknownFormats.indexOf(" + $schemaValue + ") == -1 ";
      }

      out += ") || ";
    }

    out += " (" + $format + " && " + $formatType + " == '" + $ruleType + "' && !(typeof " + $format + " == 'function' ? ";

    if (it.async) {
      out += " (async" + $lvl + " ? await " + $format + "(" + $data + ") : " + $format + "(" + $data + ")) ";
    } else {
      out += " " + $format + "(" + $data + ") ";
    }

    out += " : " + $format + ".test(" + $data + "))))) {";
  } else {
    var $format = it.formats[$schema];

    if (!$format) {
      if ($unknownFormats == "ignore") {
        it.logger.warn("unknown format \"" + $schema + "\" ignored in schema at path \"" + it.errSchemaPath + "\"");

        if ($breakOnError) {
          out += " if (true) { ";
        }

        return out;
      } else if ($allowUnknown && $unknownFormats.indexOf($schema) >= 0) {
        if ($breakOnError) {
          out += " if (true) { ";
        }

        return out;
      } else {
        throw new Error("unknown format \"" + $schema + "\" is used in schema at path \"" + it.errSchemaPath + "\"");
      }
    }

    var $isObject = typeof $format == "object" && !($format instanceof RegExp) && $format.validate;
    var $formatType = $isObject && $format.type || "string";

    if ($isObject) {
      var $async = $format.async === true;
      $format = $format.validate;
    }

    if ($formatType != $ruleType) {
      if ($breakOnError) {
        out += " if (true) { ";
      }

      return out;
    }

    if ($async) {
      if (!it.async) throw new Error("async format in sync schema");
      var $formatRef = "formats" + it.util.getProperty($schema) + ".validate";
      out += " if (!(await " + $formatRef + "(" + $data + "))) { ";
    } else {
      out += " if (! ";
      var $formatRef = "formats" + it.util.getProperty($schema);
      if ($isObject) $formatRef += ".validate";

      if (typeof $format == "function") {
        out += " " + $formatRef + "(" + $data + ") ";
      } else {
        out += " " + $formatRef + ".test(" + $data + ") ";
      }

      out += ") { ";
    }
  }

  var $$outStack = $$outStack || [];
  $$outStack.push(out);
  out = "";
  /* istanbul ignore else */

  if (it.createErrors !== false) {
    out += " { keyword: '" + "format" + "' , dataPath: (dataPath || '') + " + it.errorPath + " , schemaPath: " + it.util.toQuotedString($errSchemaPath) + " , params: { format:  ";

    if ($isData) {
      out += "" + $schemaValue;
    } else {
      out += "" + it.util.toQuotedString($schema);
    }

    out += "  } ";

    if (it.opts.messages !== false) {
      out += " , message: 'should match format \"";

      if ($isData) {
        out += "' + " + $schemaValue + " + '";
      } else {
        out += "" + it.util.escapeQuotes($schema);
      }

      out += "\"' ";
    }

    if (it.opts.verbose) {
      out += " , schema:  ";

      if ($isData) {
        out += "validate.schema" + $schemaPath;
      } else {
        out += "" + it.util.toQuotedString($schema);
      }

      out += "         , parentSchema: validate.schema" + it.schemaPath + " , data: " + $data + " ";
    }

    out += " } ";
  } else {
    out += " {} ";
  }

  var __err = out;
  out = $$outStack.pop();

  if (!it.compositeRule && $breakOnError) {
    /* istanbul ignore if */
    if (it.async) {
      out += " throw new ValidationError([" + __err + "]); ";
    } else {
      out += " validate.errors = [" + __err + "]; return false; ";
    }
  } else {
    out += " var err = " + __err + ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ";
  }

  out += " } ";

  if ($breakOnError) {
    out += " else { ";
  }

  return out;
};

export default exports;