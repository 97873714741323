var exports = {};
var KEYWORDS = ["multipleOf", "maximum", "exclusiveMaximum", "minimum", "exclusiveMinimum", "maxLength", "minLength", "pattern", "additionalItems", "maxItems", "minItems", "uniqueItems", "maxProperties", "minProperties", "required", "additionalProperties", "enum", "format", "const"];

exports = function (metaSchema, keywordsJsonPointers) {
  for (var i = 0; i < keywordsJsonPointers.length; i++) {
    metaSchema = JSON.parse(JSON.stringify(metaSchema));
    var segments = keywordsJsonPointers[i].split("/");
    var keywords = metaSchema;
    var j;

    for (j = 1; j < segments.length; j++) keywords = keywords[segments[j]];

    for (j = 0; j < KEYWORDS.length; j++) {
      var key = KEYWORDS[j];
      var schema = keywords[key];

      if (schema) {
        keywords[key] = {
          anyOf: [schema, {
            $ref: "https://raw.githubusercontent.com/ajv-validator/ajv/master/lib/refs/data.json#"
          }]
        };
      }
    }
  }

  return metaSchema;
};

export default exports;