var exports = {};

exports = function generate_uniqueItems(it, $keyword, $ruleType) {
  var out = " ";
  var $lvl = it.level;
  var $dataLvl = it.dataLevel;
  var $schema = it.schema[$keyword];
  var $schemaPath = it.schemaPath + it.util.getProperty($keyword);
  var $errSchemaPath = it.errSchemaPath + "/" + $keyword;
  var $breakOnError = !it.opts.allErrors;
  var $data = "data" + ($dataLvl || "");
  var $valid = "valid" + $lvl;
  var $isData = it.opts.$data && $schema && $schema.$data,
      $schemaValue;

  if ($isData) {
    out += " var schema" + $lvl + " = " + it.util.getData($schema.$data, $dataLvl, it.dataPathArr) + "; ";
    $schemaValue = "schema" + $lvl;
  } else {
    $schemaValue = $schema;
  }

  if (($schema || $isData) && it.opts.uniqueItems !== false) {
    if ($isData) {
      out += " var " + $valid + "; if (" + $schemaValue + " === false || " + $schemaValue + " === undefined) " + $valid + " = true; else if (typeof " + $schemaValue + " != 'boolean') " + $valid + " = false; else { ";
    }

    out += " var i = " + $data + ".length , " + $valid + " = true , j; if (i > 1) { ";
    var $itemType = it.schema.items && it.schema.items.type,
        $typeIsArray = Array.isArray($itemType);

    if (!$itemType || $itemType == "object" || $itemType == "array" || $typeIsArray && ($itemType.indexOf("object") >= 0 || $itemType.indexOf("array") >= 0)) {
      out += " outer: for (;i--;) { for (j = i; j--;) { if (equal(" + $data + "[i], " + $data + "[j])) { " + $valid + " = false; break outer; } } } ";
    } else {
      out += " var itemIndices = {}, item; for (;i--;) { var item = " + $data + "[i]; ";
      var $method = "checkDataType" + ($typeIsArray ? "s" : "");
      out += " if (" + it.util[$method]($itemType, "item", it.opts.strictNumbers, true) + ") continue; ";

      if ($typeIsArray) {
        out += " if (typeof item == 'string') item = '\"' + item; ";
      }

      out += " if (typeof itemIndices[item] == 'number') { " + $valid + " = false; j = itemIndices[item]; break; } itemIndices[item] = i; } ";
    }

    out += " } ";

    if ($isData) {
      out += "  }  ";
    }

    out += " if (!" + $valid + ") {   ";
    var $$outStack = $$outStack || [];
    $$outStack.push(out);
    out = "";
    /* istanbul ignore else */

    if (it.createErrors !== false) {
      out += " { keyword: '" + "uniqueItems" + "' , dataPath: (dataPath || '') + " + it.errorPath + " , schemaPath: " + it.util.toQuotedString($errSchemaPath) + " , params: { i: i, j: j } ";

      if (it.opts.messages !== false) {
        out += " , message: 'should NOT have duplicate items (items ## ' + j + ' and ' + i + ' are identical)' ";
      }

      if (it.opts.verbose) {
        out += " , schema:  ";

        if ($isData) {
          out += "validate.schema" + $schemaPath;
        } else {
          out += "" + $schema;
        }

        out += "         , parentSchema: validate.schema" + it.schemaPath + " , data: " + $data + " ";
      }

      out += " } ";
    } else {
      out += " {} ";
    }

    var __err = out;
    out = $$outStack.pop();

    if (!it.compositeRule && $breakOnError) {
      /* istanbul ignore if */
      if (it.async) {
        out += " throw new ValidationError([" + __err + "]); ";
      } else {
        out += " validate.errors = [" + __err + "]; return false; ";
      }
    } else {
      out += " var err = " + __err + ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ";
    }

    out += " } ";

    if ($breakOnError) {
      out += " else { ";
    }
  } else {
    if ($breakOnError) {
      out += " if (true) { ";
    }
  }

  return out;
};

export default exports;