import _ref from "./ref";
import _allOf from "./allOf";
import _anyOf from "./anyOf";
import _comment from "./comment";
import _const from "./const";
import _contains from "./contains";
import _dependencies from "./dependencies";
import _enum from "./enum";
import _format from "./format";
import _if from "./if";
import _items from "./items";
import _limit from "./_limit";
import _limitItems from "./_limitItems";
import _limitLength from "./_limitLength";
import _limitProperties from "./_limitProperties";
import _multipleOf from "./multipleOf";
import _not from "./not";
import _oneOf from "./oneOf";
import _pattern from "./pattern";
import _properties from "./properties";
import _propertyNames from "./propertyNames";
import _required from "./required";
import _uniqueItems from "./uniqueItems";
import _validate from "./validate";
var exports = {};
//all requires must be explicit because browserify won't work with dynamic requires
exports = {
  "$ref": _ref,
  allOf: _allOf,
  anyOf: _anyOf,
  "$comment": _comment,
  const: _const,
  contains: _contains,
  dependencies: _dependencies,
  "enum": _enum,
  format: _format,
  "if": _if,
  items: _items,
  maximum: _limit,
  minimum: _limit,
  maxItems: _limitItems,
  minItems: _limitItems,
  maxLength: _limitLength,
  minLength: _limitLength,
  maxProperties: _limitProperties,
  minProperties: _limitProperties,
  multipleOf: _multipleOf,
  not: _not,
  oneOf: _oneOf,
  pattern: _pattern,
  properties: _properties,
  propertyNames: _propertyNames,
  required: _required,
  uniqueItems: _uniqueItems,
  validate: _validate
};
export default exports;