var exports = {};

exports = function generate_required(it, $keyword, $ruleType) {
  var out = " ";
  var $lvl = it.level;
  var $dataLvl = it.dataLevel;
  var $schema = it.schema[$keyword];
  var $schemaPath = it.schemaPath + it.util.getProperty($keyword);
  var $errSchemaPath = it.errSchemaPath + "/" + $keyword;
  var $breakOnError = !it.opts.allErrors;
  var $data = "data" + ($dataLvl || "");
  var $valid = "valid" + $lvl;
  var $isData = it.opts.$data && $schema && $schema.$data,
      $schemaValue;

  if ($isData) {
    out += " var schema" + $lvl + " = " + it.util.getData($schema.$data, $dataLvl, it.dataPathArr) + "; ";
    $schemaValue = "schema" + $lvl;
  } else {
    $schemaValue = $schema;
  }

  var $vSchema = "schema" + $lvl;

  if (!$isData) {
    if ($schema.length < it.opts.loopRequired && it.schema.properties && Object.keys(it.schema.properties).length) {
      var $required = [];
      var arr1 = $schema;

      if (arr1) {
        var $property,
            i1 = -1,
            l1 = arr1.length - 1;

        while (i1 < l1) {
          $property = arr1[i1 += 1];
          var $propertySch = it.schema.properties[$property];

          if (!($propertySch && (it.opts.strictKeywords ? typeof $propertySch == "object" && Object.keys($propertySch).length > 0 || $propertySch === false : it.util.schemaHasRules($propertySch, it.RULES.all)))) {
            $required[$required.length] = $property;
          }
        }
      }
    } else {
      var $required = $schema;
    }
  }

  if ($isData || $required.length) {
    var $currentErrorPath = it.errorPath,
        $loopRequired = $isData || $required.length >= it.opts.loopRequired,
        $ownProperties = it.opts.ownProperties;

    if ($breakOnError) {
      out += " var missing" + $lvl + "; ";

      if ($loopRequired) {
        if (!$isData) {
          out += " var " + $vSchema + " = validate.schema" + $schemaPath + "; ";
        }

        var $i = "i" + $lvl,
            $propertyPath = "schema" + $lvl + "[" + $i + "]",
            $missingProperty = "' + " + $propertyPath + " + '";

        if (it.opts._errorDataPathProperty) {
          it.errorPath = it.util.getPathExpr($currentErrorPath, $propertyPath, it.opts.jsonPointers);
        }

        out += " var " + $valid + " = true; ";

        if ($isData) {
          out += " if (schema" + $lvl + " === undefined) " + $valid + " = true; else if (!Array.isArray(schema" + $lvl + ")) " + $valid + " = false; else {";
        }

        out += " for (var " + $i + " = 0; " + $i + " < " + $vSchema + ".length; " + $i + "++) { " + $valid + " = " + $data + "[" + $vSchema + "[" + $i + "]] !== undefined ";

        if ($ownProperties) {
          out += " &&   Object.prototype.hasOwnProperty.call(" + $data + ", " + $vSchema + "[" + $i + "]) ";
        }

        out += "; if (!" + $valid + ") break; } ";

        if ($isData) {
          out += "  }  ";
        }

        out += "  if (!" + $valid + ") {   ";
        var $$outStack = $$outStack || [];
        $$outStack.push(out);
        out = "";
        /* istanbul ignore else */

        if (it.createErrors !== false) {
          out += " { keyword: '" + "required" + "' , dataPath: (dataPath || '') + " + it.errorPath + " , schemaPath: " + it.util.toQuotedString($errSchemaPath) + " , params: { missingProperty: '" + $missingProperty + "' } ";

          if (it.opts.messages !== false) {
            out += " , message: '";

            if (it.opts._errorDataPathProperty) {
              out += "is a required property";
            } else {
              out += "should have required property \\'" + $missingProperty + "\\'";
            }

            out += "' ";
          }

          if (it.opts.verbose) {
            out += " , schema: validate.schema" + $schemaPath + " , parentSchema: validate.schema" + it.schemaPath + " , data: " + $data + " ";
          }

          out += " } ";
        } else {
          out += " {} ";
        }

        var __err = out;
        out = $$outStack.pop();

        if (!it.compositeRule && $breakOnError) {
          /* istanbul ignore if */
          if (it.async) {
            out += " throw new ValidationError([" + __err + "]); ";
          } else {
            out += " validate.errors = [" + __err + "]; return false; ";
          }
        } else {
          out += " var err = " + __err + ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ";
        }

        out += " } else { ";
      } else {
        out += " if ( ";
        var arr2 = $required;

        if (arr2) {
          var $propertyKey,
              $i = -1,
              l2 = arr2.length - 1;

          while ($i < l2) {
            $propertyKey = arr2[$i += 1];

            if ($i) {
              out += " || ";
            }

            var $prop = it.util.getProperty($propertyKey),
                $useData = $data + $prop;
            out += " ( ( " + $useData + " === undefined ";

            if ($ownProperties) {
              out += " || ! Object.prototype.hasOwnProperty.call(" + $data + ", '" + it.util.escapeQuotes($propertyKey) + "') ";
            }

            out += ") && (missing" + $lvl + " = " + it.util.toQuotedString(it.opts.jsonPointers ? $propertyKey : $prop) + ") ) ";
          }
        }

        out += ") {  ";
        var $propertyPath = "missing" + $lvl,
            $missingProperty = "' + " + $propertyPath + " + '";

        if (it.opts._errorDataPathProperty) {
          it.errorPath = it.opts.jsonPointers ? it.util.getPathExpr($currentErrorPath, $propertyPath, true) : $currentErrorPath + " + " + $propertyPath;
        }

        var $$outStack = $$outStack || [];
        $$outStack.push(out);
        out = "";
        /* istanbul ignore else */

        if (it.createErrors !== false) {
          out += " { keyword: '" + "required" + "' , dataPath: (dataPath || '') + " + it.errorPath + " , schemaPath: " + it.util.toQuotedString($errSchemaPath) + " , params: { missingProperty: '" + $missingProperty + "' } ";

          if (it.opts.messages !== false) {
            out += " , message: '";

            if (it.opts._errorDataPathProperty) {
              out += "is a required property";
            } else {
              out += "should have required property \\'" + $missingProperty + "\\'";
            }

            out += "' ";
          }

          if (it.opts.verbose) {
            out += " , schema: validate.schema" + $schemaPath + " , parentSchema: validate.schema" + it.schemaPath + " , data: " + $data + " ";
          }

          out += " } ";
        } else {
          out += " {} ";
        }

        var __err = out;
        out = $$outStack.pop();

        if (!it.compositeRule && $breakOnError) {
          /* istanbul ignore if */
          if (it.async) {
            out += " throw new ValidationError([" + __err + "]); ";
          } else {
            out += " validate.errors = [" + __err + "]; return false; ";
          }
        } else {
          out += " var err = " + __err + ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ";
        }

        out += " } else { ";
      }
    } else {
      if ($loopRequired) {
        if (!$isData) {
          out += " var " + $vSchema + " = validate.schema" + $schemaPath + "; ";
        }

        var $i = "i" + $lvl,
            $propertyPath = "schema" + $lvl + "[" + $i + "]",
            $missingProperty = "' + " + $propertyPath + " + '";

        if (it.opts._errorDataPathProperty) {
          it.errorPath = it.util.getPathExpr($currentErrorPath, $propertyPath, it.opts.jsonPointers);
        }

        if ($isData) {
          out += " if (" + $vSchema + " && !Array.isArray(" + $vSchema + ")) {  var err =   ";
          /* istanbul ignore else */

          if (it.createErrors !== false) {
            out += " { keyword: '" + "required" + "' , dataPath: (dataPath || '') + " + it.errorPath + " , schemaPath: " + it.util.toQuotedString($errSchemaPath) + " , params: { missingProperty: '" + $missingProperty + "' } ";

            if (it.opts.messages !== false) {
              out += " , message: '";

              if (it.opts._errorDataPathProperty) {
                out += "is a required property";
              } else {
                out += "should have required property \\'" + $missingProperty + "\\'";
              }

              out += "' ";
            }

            if (it.opts.verbose) {
              out += " , schema: validate.schema" + $schemaPath + " , parentSchema: validate.schema" + it.schemaPath + " , data: " + $data + " ";
            }

            out += " } ";
          } else {
            out += " {} ";
          }

          out += ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; } else if (" + $vSchema + " !== undefined) { ";
        }

        out += " for (var " + $i + " = 0; " + $i + " < " + $vSchema + ".length; " + $i + "++) { if (" + $data + "[" + $vSchema + "[" + $i + "]] === undefined ";

        if ($ownProperties) {
          out += " || ! Object.prototype.hasOwnProperty.call(" + $data + ", " + $vSchema + "[" + $i + "]) ";
        }

        out += ") {  var err =   ";
        /* istanbul ignore else */

        if (it.createErrors !== false) {
          out += " { keyword: '" + "required" + "' , dataPath: (dataPath || '') + " + it.errorPath + " , schemaPath: " + it.util.toQuotedString($errSchemaPath) + " , params: { missingProperty: '" + $missingProperty + "' } ";

          if (it.opts.messages !== false) {
            out += " , message: '";

            if (it.opts._errorDataPathProperty) {
              out += "is a required property";
            } else {
              out += "should have required property \\'" + $missingProperty + "\\'";
            }

            out += "' ";
          }

          if (it.opts.verbose) {
            out += " , schema: validate.schema" + $schemaPath + " , parentSchema: validate.schema" + it.schemaPath + " , data: " + $data + " ";
          }

          out += " } ";
        } else {
          out += " {} ";
        }

        out += ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; } } ";

        if ($isData) {
          out += "  }  ";
        }
      } else {
        var arr3 = $required;

        if (arr3) {
          var $propertyKey,
              i3 = -1,
              l3 = arr3.length - 1;

          while (i3 < l3) {
            $propertyKey = arr3[i3 += 1];
            var $prop = it.util.getProperty($propertyKey),
                $missingProperty = it.util.escapeQuotes($propertyKey),
                $useData = $data + $prop;

            if (it.opts._errorDataPathProperty) {
              it.errorPath = it.util.getPath($currentErrorPath, $propertyKey, it.opts.jsonPointers);
            }

            out += " if ( " + $useData + " === undefined ";

            if ($ownProperties) {
              out += " || ! Object.prototype.hasOwnProperty.call(" + $data + ", '" + it.util.escapeQuotes($propertyKey) + "') ";
            }

            out += ") {  var err =   ";
            /* istanbul ignore else */

            if (it.createErrors !== false) {
              out += " { keyword: '" + "required" + "' , dataPath: (dataPath || '') + " + it.errorPath + " , schemaPath: " + it.util.toQuotedString($errSchemaPath) + " , params: { missingProperty: '" + $missingProperty + "' } ";

              if (it.opts.messages !== false) {
                out += " , message: '";

                if (it.opts._errorDataPathProperty) {
                  out += "is a required property";
                } else {
                  out += "should have required property \\'" + $missingProperty + "\\'";
                }

                out += "' ";
              }

              if (it.opts.verbose) {
                out += " , schema: validate.schema" + $schemaPath + " , parentSchema: validate.schema" + it.schemaPath + " , data: " + $data + " ";
              }

              out += " } ";
            } else {
              out += " {} ";
            }

            out += ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; } ";
          }
        }
      }
    }

    it.errorPath = $currentErrorPath;
  } else if ($breakOnError) {
    out += " if (true) {";
  }

  return out;
};

export default exports;