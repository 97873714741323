import _jsonSchemaDraft from "./refs/json-schema-draft-07.json";
var exports = {};
var metaSchema = _jsonSchemaDraft;
exports = {
  $id: "https://github.com/ajv-validator/ajv/blob/master/lib/definition_schema.js",
  definitions: {
    simpleTypes: metaSchema.definitions.simpleTypes
  },
  type: "object",
  dependencies: {
    schema: ["validate"],
    $data: ["validate"],
    statements: ["inline"],
    valid: {
      not: {
        required: ["macro"]
      }
    }
  },
  properties: {
    type: metaSchema.properties.type,
    schema: {
      type: "boolean"
    },
    statements: {
      type: "boolean"
    },
    dependencies: {
      type: "array",
      items: {
        type: "string"
      }
    },
    metaSchema: {
      type: "object"
    },
    modifying: {
      type: "boolean"
    },
    valid: {
      type: "boolean"
    },
    $data: {
      type: "boolean"
    },
    async: {
      type: "boolean"
    },
    errors: {
      anyOf: [{
        type: "boolean"
      }, {
        const: "full"
      }]
    }
  }
};
export default exports;