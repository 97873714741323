var exports = {};

exports = function generate_if(it, $keyword, $ruleType) {
  var out = " ";
  var $lvl = it.level;
  var $dataLvl = it.dataLevel;
  var $schema = it.schema[$keyword];
  var $schemaPath = it.schemaPath + it.util.getProperty($keyword);
  var $errSchemaPath = it.errSchemaPath + "/" + $keyword;
  var $breakOnError = !it.opts.allErrors;
  var $data = "data" + ($dataLvl || "");
  var $valid = "valid" + $lvl;
  var $errs = "errs__" + $lvl;
  var $it = it.util.copy(it);
  $it.level++;
  var $nextValid = "valid" + $it.level;
  var $thenSch = it.schema["then"],
      $elseSch = it.schema["else"],
      $thenPresent = $thenSch !== undefined && (it.opts.strictKeywords ? typeof $thenSch == "object" && Object.keys($thenSch).length > 0 || $thenSch === false : it.util.schemaHasRules($thenSch, it.RULES.all)),
      $elsePresent = $elseSch !== undefined && (it.opts.strictKeywords ? typeof $elseSch == "object" && Object.keys($elseSch).length > 0 || $elseSch === false : it.util.schemaHasRules($elseSch, it.RULES.all)),
      $currentBaseId = $it.baseId;

  if ($thenPresent || $elsePresent) {
    var $ifClause;
    $it.createErrors = false;
    $it.schema = $schema;
    $it.schemaPath = $schemaPath;
    $it.errSchemaPath = $errSchemaPath;
    out += " var " + $errs + " = errors; var " + $valid + " = true;  ";
    var $wasComposite = it.compositeRule;
    it.compositeRule = $it.compositeRule = true;
    out += "  " + it.validate($it) + " ";
    $it.baseId = $currentBaseId;
    $it.createErrors = true;
    out += "  errors = " + $errs + "; if (vErrors !== null) { if (" + $errs + ") vErrors.length = " + $errs + "; else vErrors = null; }  ";
    it.compositeRule = $it.compositeRule = $wasComposite;

    if ($thenPresent) {
      out += " if (" + $nextValid + ") {  ";
      $it.schema = it.schema["then"];
      $it.schemaPath = it.schemaPath + ".then";
      $it.errSchemaPath = it.errSchemaPath + "/then";
      out += "  " + it.validate($it) + " ";
      $it.baseId = $currentBaseId;
      out += " " + $valid + " = " + $nextValid + "; ";

      if ($thenPresent && $elsePresent) {
        $ifClause = "ifClause" + $lvl;
        out += " var " + $ifClause + " = 'then'; ";
      } else {
        $ifClause = "'then'";
      }

      out += " } ";

      if ($elsePresent) {
        out += " else { ";
      }
    } else {
      out += " if (!" + $nextValid + ") { ";
    }

    if ($elsePresent) {
      $it.schema = it.schema["else"];
      $it.schemaPath = it.schemaPath + ".else";
      $it.errSchemaPath = it.errSchemaPath + "/else";
      out += "  " + it.validate($it) + " ";
      $it.baseId = $currentBaseId;
      out += " " + $valid + " = " + $nextValid + "; ";

      if ($thenPresent && $elsePresent) {
        $ifClause = "ifClause" + $lvl;
        out += " var " + $ifClause + " = 'else'; ";
      } else {
        $ifClause = "'else'";
      }

      out += " } ";
    }

    out += " if (!" + $valid + ") {   var err =   ";
    /* istanbul ignore else */

    if (it.createErrors !== false) {
      out += " { keyword: '" + "if" + "' , dataPath: (dataPath || '') + " + it.errorPath + " , schemaPath: " + it.util.toQuotedString($errSchemaPath) + " , params: { failingKeyword: " + $ifClause + " } ";

      if (it.opts.messages !== false) {
        out += " , message: 'should match \"' + " + $ifClause + " + '\" schema' ";
      }

      if (it.opts.verbose) {
        out += " , schema: validate.schema" + $schemaPath + " , parentSchema: validate.schema" + it.schemaPath + " , data: " + $data + " ";
      }

      out += " } ";
    } else {
      out += " {} ";
    }

    out += ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ";

    if (!it.compositeRule && $breakOnError) {
      /* istanbul ignore if */
      if (it.async) {
        out += " throw new ValidationError(vErrors); ";
      } else {
        out += " validate.errors = vErrors; return false; ";
      }
    }

    out += " }   ";

    if ($breakOnError) {
      out += " else { ";
    }
  } else {
    if ($breakOnError) {
      out += " if (true) { ";
    }
  }

  return out;
};

export default exports;