var exports = {};

var Cache = exports = function Cache() {
  this._cache = {};
};

Cache.prototype.put = function Cache_put(key, value) {
  this._cache[key] = value;
};

Cache.prototype.get = function Cache_get(key) {
  return this._cache[key];
};

Cache.prototype.del = function Cache_del(key) {
  delete this._cache[key];
};

Cache.prototype.clear = function Cache_clear() {
  this._cache = {};
};

export default exports;