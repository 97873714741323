var exports = {};

exports = function generate_comment(it, $keyword, $ruleType) {
  var out = " ";
  var $schema = it.schema[$keyword];
  var $errSchemaPath = it.errSchemaPath + "/" + $keyword;
  var $breakOnError = !it.opts.allErrors;
  var $comment = it.util.toQuotedString($schema);

  if (it.opts.$comment === true) {
    out += " console.log(" + $comment + ");";
  } else if (typeof it.opts.$comment == "function") {
    out += " self._opts.$comment(" + $comment + ", " + it.util.toQuotedString($errSchemaPath) + ", validate.root.schema);";
  }

  return out;
};

export default exports;